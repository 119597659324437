import React, { Component } from "react";
import openSocket from "socket.io-client";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/home";
import Certificate from "./components/Certificate/Certificate";
import CreateCred from "./generateCred";
import EmpCertificate from "./components/EmpCred/certificate";
import DocumentMetadata from "./components/UploadDoc/UploadPdf";
import Admin from "./components/Admin/Admin";
import ServiceNow from "./components/certserviceNow/serviceNow";
import Subscribe from "./components/Subscribe/subs";
import IDCertificate from "./components/IDCertificate/NewIDCertificate";
import GradCertificate from "./components/GradCert/GradCert";
import Baseui from "./components/BaseUI/baseui";
import Project from "./components/Certificate/Certificate";
import Training from "./components/TrainCert/Certificate";
import Professional from "./components/ProfMemCert/Certificate";
import Test from "./components/HomePublic/test";
import TrainingNew from "./components/TrainCert copy/Certificate";

// export const isTestnet = true;
// const socketURL =
//   isTestnet === true
//     ? "https://socket-stagev2.myearth.id"
//     : "https://socketv2.myearth.id";
// const socket = openSocket(socketURL, {
//   transports: ["websocket", "polling", "flashsocket"],
// });
// let socketId;
// socket.on("connect", () => {
//   socketId = socket.id;
//   console.log(`I'm connected with the back-end`);
// });



class App extends Component {


  render() {
    return (
      <div className="App">


          <div className="content">
            <Router>
              <Switch>
              <Route exact path="/" component={Test} />
              <Route path="/uploadDoc" component={DocumentMetadata} />
              <Route path="/admin/" component={Admin} />
              <Route path="/certificate/death/:userName" component={Project} />
              <Route path="/certificate/employment/:userName" component={IDCertificate} />
              <Route path="/certificate/marriage/:userName" component={Training} />
              <Route path="/certificate/marriageCert/:userName" component={TrainingNew} />
              <Route path="/certificate/birth/:userName" component={Professional} />
              <Route path="/certificate/ServiceNow/" component={ServiceNow} />
              <Route path="/transactions" component={Subscribe} />
              </Switch>
            </Router>
          </div>
      </div>
    );
  }
}

export default App;
