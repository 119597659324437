import React, { useEffect, useState } from 'react';
import './subs.css';

function Subscribe() {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetch('https://publicdb.myearth.id/api/subs/messages')
      .then(response => response.json())
      .then(data => {
        setMessages(data);
      })
      .catch(error => {
        console.error('Error fetching messages:', error);
      });
  }, []);

  return (
    <div className="containerrr">
      <h1>Transaction History</h1>
      <table className="message-table">
        <thead>
          <tr>
            <th>Index</th>
            <th>Topic ID</th>
            <th>Transaction ID</th>
            <th>Flow Name</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{message.topicId}</td>
              <td>{message.transactionID}</td>
              <td>{message.flowName}</td>
              <td>{message.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Subscribe;
