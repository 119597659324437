import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Document, Page } from 'react-pdf';

const DocumentViewer = ({ document, onClose }) => {
  return (
    <div className="document-popup">
      <div className="popup-content">
      <div className="close-button-container">
    <FontAwesomeIcon className="close-button" onClick={onClose} icon={faClose} />
 
</div>
      
        <h3>Preview:</h3>
          
        <Document file={document}>
          <Page pageNumber={1} width={400} height={500} />
        </Document>
      </div>
    </div>
  );
};

export default DocumentViewer;