// import React from 'react';
// import "./admin.css"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye } from '@fortawesome/free-solid-svg-icons';

// const DocumentTable = ({ pdfs, onDocumentView }) => {
//   return (
//     <div className="user-tablee">
//       <table className="user-table">
//         <thead>
//           <tr>
//             <th>Sr No.</th>
//             <th>PDF Name</th>
//             <th>Status</th>
//             <th>View</th>
//           </tr>
//         </thead>
//         <tbody>
//           {pdfs.map((pdf, index) => (
//             <tr key={`document_${index}`}>
//               <td>{index + 1}</td>
//               <td>{pdf}</td>
//               <td>
//                 <FontAwesomeIcon onClick={() => onDocumentView(pdf)} className='eyeIcon' icon={faEye} />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default DocumentTable;
import React, { useState, useEffect } from 'react';
import './admin.css';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const DocumentTable = ({ pdfs, onDocumentView }) => {
  const getRandomStatus = () => {
    const statuses = ['approved', 'rejected', 'Uploaded'];
    return statuses[Math.floor(Math.random() * statuses.length)];
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return 'green';
      case 'rejected':
        return 'red';
      case 'Uploaded':
        return 'black';
      default:
        return 'black';
    }
  };

  const [pdfsWithStatus, setPdfsWithStatus] = useState(pdfs.response.map((pdf) => ({ pdf, status: 'Uploaded' })));
  const [loading, setLoading] = useState(false); // Add loading state

  // Function to add a new entry and set status as "Uploaded"
  const addNewEntry = (newPdf) => {
    setPdfsWithStatus([...pdfsWithStatus, { pdf: newPdf, status: 'Uploaded' }]);
  };

  // Function to handle delete button click
  const handleDeleteClick = (pdfName, docID) => {
    console.log('DocID', docID);
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to delete the document "${pdfName}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Make a POST request to the API with the documentsID
          const response = await Axios.post('https://publicdb.myearth.id/api/docnew/delete', {
            documentsID: docID, // Assuming docID is the documentsID
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          console.log('response', response);
          if (response.status === 200) {
            // Successfully deleted the document
            const updatedPdfsWithStatus = pdfsWithStatus.filter(item => item.pdf._id !== docID);
setPdfsWithStatus(updatedPdfsWithStatus);

            Swal.fire('Deleted!', `The document "${pdfName}" has been deleted.`, 'success');
          } else {
            Swal.fire('Error', 'Failed to delete the document.', 'error');
          }
        } catch (error) {
          Swal.fire('Error', 'Failed to delete the document.', 'error');
        }
      }
    });
  };
  

  // Add an effect to update the table when pdfs prop changes (when a document is added or deleted)
  useEffect(() => {
    setPdfsWithStatus(pdfs.response.map((pdf) => ({ pdf, status: 'Uploaded' })));
  }, [pdfs]);

  return (
    <div className="user-tablee">
      <table className="user-table">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Document Name</th>
            <th>Status</th>
            <th>View</th>
            <th>Delete Document</th>
          </tr>
        </thead>
        <tbody>
          {pdfsWithStatus.map((item, index) => (
            <tr key={`document_${index}`}>
              <td>{index + 1}</td>
              <td>{item.pdf.originalFileName}</td>
              <td style={{ color: getStatusColor(item.status) }}>{item.status}</td>
              <td>
                <FontAwesomeIcon onClick={() => onDocumentView(item.pdf.doc, item.pdf.metaData, item.pdf._id, item.pdf.docHash)} className="eyeIcon" icon={faEye} />
              </td>
              <td>
                <FontAwesomeIcon  onClick={() => handleDeleteClick(item.pdf.originalFileName, item.pdf._id)} className="deleteIcon" icon={faTrash} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DocumentTable;

